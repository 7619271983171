var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.availability && _vm.allVariationsSelected && _vm.canBeAddedToBasket
      ? _c("span", { class: _vm.classes, style: _vm.paddingStyles }, [
          _c("span", [
            _vm._v("\r\n            " + _vm._s(_vm.name) + "\r\n        ")
          ])
        ])
      : _c("span", { staticClass: "availability badge availability-none" }, [
          !_vm.allVariationsSelected || !_vm.canBeAddedToBasket
            ? _c("span", [
                _vm._v(
                  "\r\n            " + _vm._s(_vm.infoText) + "\r\n        "
                )
              ])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }