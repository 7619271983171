<template>
<div>
    <span v-if="availability && allVariationsSelected && canBeAddedToBasket"
        :class="classes"
        :style="paddingStyles">
        <span>
            {{ name }}
        </span>
    </span>
    <span v-else class="availability badge availability-none">
        <span v-if="!allVariationsSelected || !canBeAddedToBasket">
            {{ infoText }}
        </span>
    </span>
</div>
</template>

<script>
export default {
    props: {
        paddingClasses: String,
        paddingStyles: String
    },

    inject: {
        itemId: {
            default: null
        }
    },

    computed:
    {
        currentVariation() {
            return this.$store.getters[`${this.itemId}/currentItemVariation`];;
        },
        availability() {
            return this.currentVariation && this.currentVariation.variation && this.currentVariation.variation.availability;
        },

        classes() {
            return [
                "availability",
                "badge",
                "availability-" + (this.availability && this.availability.id),
                this.paddingClasses
            ];
        },

        name() {
            return this.availability && this.availability.names && this.availability.names.name;
        },

        isSet()
        {
            return (
                this.$store.state.items[this.itemId]
                && this.$store.state.items[this.itemId].variation
                && this.$store.state.items[this.itemId].variation.documents[0].data.item.itemType === "set"
            );
        },

        allVariationsSelected()
        {
            if (this.isSet)
            {
                return this.$store.getters["itemSetAllVariationSelected"];
            }
            else
            {
                // FIX return true if module is not registered. This equals the default value from the module
                // and is required to use this component in other contexts, e.g. the category view
                return !this.$store.state.items[this.itemId]
                    || (this.$store.state.items[this.itemId].variationSelect
                        && this.$store.state.items[this.itemId].variationSelect.isVariationSelected);
            }
        },
        canBeAddedToBasket()
        {
            const hasPrice = this.$options.filters.hasItemDefaultPrice(this.currentVariation);
            const intervalQuantity = this.currentVariation.variation.intervalOrderQuantity || 1;
            const minimumQuantity = this.currentVariation.variation.minimumOrderQuantity || 0;
            const hasChildren = this.currentVariation.filter && this.currentVariation.filter.hasActiveChildren || false;
            const isSalable = this.currentVariation.filter && this.currentVariation.filter.isSalable || false;

            return isSalable &&
                !hasChildren &&
                !(minimumQuantity != 1 || intervalQuantity != 1) &&
                !this.requiresProperties &&
                hasPrice &&
                !this.isSet;
        },
        requiresProperties()
        {
            const f = () => [];
            const orderProperties = this.currentVariation.properties.filter(function(prop) { return prop.property.isOderProperty }) || f;
            const hasOrderProperties = this.currentVariation.hasOrderProperties || false;
            return App.config.item.requireOrderProperties &&
                (hasOrderProperties || orderProperties.filter(property => property.property.isShownOnItemPage).length > 0);
        },
        infoText()
        {
            if (this.hasAvailableVariations)
            {
                return this.$translate("Ceres::Template.singleItemPleaseSelectValidVariation");
            }
            else
            {
                return this.$translate("Ceres::Template.singleItemPleaseSelectNotAvailable");
            }
        },
        hasAvailableVariations()
        {
            return this.$store.state.items[this.itemId]
                && this.$store.state.items[this.itemId].variationSelect
                && this.$store.state.items[this.itemId].variationSelect.variations.some(variation => variation.isSalable);
        },
        isAvailableVariation()
        {
            
            /*if(this.$store.state.items[this.itemId] && this.$store.state.items[this.itemId].variation && this.$store.state.items[this.itemId].variation.documents[0]){
                let variationId = this.$store.state.items[this.itemId].variation.documents[0].id;
                return this.$store.state.items[this.itemId].variationSelect
                && this.$store.state.items[this.itemId].variationSelect.variations.some(variation => variation.variationId === variationId);
            }*/
            return false;
        },
        test()
        {
            return this.$store.state.items[this.itemId];
        }
    }
}
</script>
